// Types
import { StoreStatus } from 'api/graphql-global-types';
// Hooks
import { useGetCurrUser } from 'hooks';

export const useIsProfileUnderReview = (): boolean => {
  const { data } = useGetCurrUser();

  return data?.me?.status === StoreStatus.Pending;
};
