import React from 'react';
import cn from 'classnames';
// Hooks
import { useIsProfileUnderReview } from 'hooks';
// Layouts
import ProfileNavigation from 'layouts/ProfileNavigation/ProfileNavigation';
// Styles
import styles from './Main.module.scss';

type BackgroundColor =
  | 'dark'
  | 'white'
  | 'light-gray'
  | 'cultured'
  | 'transparent'
  | 'background-default'
  | 'background-light'
  | 'background-dim'
  | 'background-main';

type MainProps = {
  className?: string;
  backgroundColor?: BackgroundColor;
  withFixedHeader?: boolean;
  withProfileNavigation?: boolean;
  withoutBottomPadding?: boolean;
  withoutSidesPadding?: boolean;
};

const Main: React.FC<MainProps> = ({
  children,
  className,
  backgroundColor,
  withFixedHeader = true,
  withProfileNavigation,
  withoutBottomPadding,
  withoutSidesPadding,
}) => {
  const isProfileUnderReview = useIsProfileUnderReview();

  return (
    <main
      className={cn(
        styles.main,
        {
          [styles[`backgroundColor-${backgroundColor}`]]: backgroundColor,
          [styles.withFixedHeader]: withFixedHeader,
          [styles.withProfileNavigation]: withProfileNavigation,
          [styles.withoutBottomPadding]: withoutBottomPadding,
          [styles.withoutSidesPadding]: withoutSidesPadding,
        },
        className
      )}
    >
      {withProfileNavigation ? (
        <div
          className={cn(styles.dashboardRoot, {
            [styles.dashboardRootProfileUnderReview]: isProfileUnderReview,
          })}
        >
          <ProfileNavigation />
          <div className={styles.dashboardContent}>{children}</div>
        </div>
      ) : (
        children
      )}
    </main>
  );
};

export default Main;
