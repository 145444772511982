import {
  ABOUT,
  CONTACT,
  CREATOR,
  EVENTS,
  INFO,
} from 'constants/external-links';
import { FIND_YOUR_ORDER } from 'constants/routes';

export const EXTERNAL_COMPANY_LINKS = [
  {
    label: 'About',
    link: ABOUT,
  },
  {
    label: 'Find your Orders',
    link: FIND_YOUR_ORDER,
  },
  {
    label: 'Help',
    link: 'mailto:info@millions.co',
  },
  {
    label: 'Contact',
    link: CONTACT,
  },
];

export const CONTACT_LINKS = [
  {
    label: 'For Creator',
    link: CREATOR,
  },
  {
    label: 'For Athlete',
    link: INFO,
  },
  {
    label: 'For PPV Event',
    link: EVENTS,
  },
  {
    label: 'For Advertisers',
    link: INFO,
  },
];

export const EXPLORE_LINKS = [
  {
    label: 'Join as Athletes',
    onClick: true,
    link: '/',
  },
  {
    label: 'Join as Creators',
    onClick: true,
    link: '/',
  },
  {
    label: 'Join as Organization',
    onClick: true,
    link: '/',
  },
  {
    label: 'Join As Fan',
    onClick: true,
    link: '/',
  },
];
