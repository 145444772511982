import type React from 'react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import Link from 'next/link';
// API
import { SAVE_EMAIL } from 'api/auth/mutations';
//Types
import { SaveEmail, SaveEmailVariables } from 'api/auth/types/SaveEmail';
import { UserRole } from 'api/graphql-global-types';
// Hooks
import { useAppContext, useGetCurrUser, useOnboardingContext } from 'hooks';
// Constants
import { DASHBOARD, PRIVACY_POLICY, TERMS_OF_SERVICE } from 'constants/routes';
// Components
import SocialNetworks from 'components/common3/SocialNetworks/SocialNetworks';
import { showToast } from 'components/common/Toast/Toast';
import { DownloadApp } from 'components/common3/DownloadApp/DownloadApp';
// UI3
import Input from 'ui3/Input/Input';
import Button from 'ui3/Button/Button';
import LinkButton from 'ui3/LinkButton/LinkButton';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';
import Text from 'ui3/Text/Text';
// Images
import FooterLogo from '../../public/images/footer/footer.svg';
// Helpers
import { getCBEnvLink } from 'helpers/routes';
import { setFullOnboardingLocalStorageFlag } from 'helpers/storage';
import {
  CONTACT_LINKS,
  EXPLORE_LINKS,
  EXTERNAL_COMPANY_LINKS,
} from './FooterHelper';
// Styles
import styles from './Footer.module.scss';

const Footer = () => {
  const [email, setEmail] = useState('');
  const { push } = useRouter();
  const [submitEmail] = useMutation<SaveEmail, SaveEmailVariables>(SAVE_EMAIL);

  const {
    setLoginModalVisibility,
    setSignUpModalVisibility,
    setSignUpStep,
  } = useAppContext();

  const { setShowOnboardingModal, setFullOnboarding } = useOnboardingContext();

  const { data } = useGetCurrUser();
  const isLoggedIn = !!data?.me?.id;

  const handleSubscribe = async () => {
    try {
      await submitEmail({ variables: { email } });
      showToast({
        message:
          'Thanks for signing up. We will send you an update as soon as we’re ' +
          'going live. If you need to get in touch in the meantime email ' +
          'matt@millions.co',
      });
    } catch (error) {
      showToast({
        message: error?.message,
        type: 'error',
      });
    }
  };

  const handleOpenLoginModal = () => {
    setLoginModalVisibility(true);
  };

  const handleOpenOnboardingModal = () => {
    setShowOnboardingModal(true);
  };

  const onFooterCtaClicked = (link) => {
    if (isLoggedIn) {
      if (data?.me.role === UserRole.Customer) {
        setShowOnboardingModal(true);
      } else {
        push(DASHBOARD);
      }
    } else {
      if (link.label === 'Join As Fan') {
        setSignUpStep(0);
        setSignUpModalVisibility(true);
      } else {
        setSignUpStep(0);
        setSignUpModalVisibility(true);
        setFullOnboarding(true);
        // For Social Sign up
        setFullOnboardingLocalStorageFlag('true');
      }
    }
  };

  const isBasicUser = data?.me?.role === UserRole.Customer;

  const showLoginModal = !isLoggedIn;
  const showOnboardingModal = isLoggedIn && isBasicUser;
  const redirectToDashboard = isLoggedIn && !isBasicUser;

  return (
    <footer className={styles.footer}>
      <div className={styles.topSection}>
        <div className={styles.newsletterSection}>
          <h2 className={styles.newsletterTitle}>DON&apos;T MISS OUT!</h2>
          <p className={styles.newsletterDescription}>
            Get notified about new products, merch drops, and upcoming streams.
          </p>

          <form onSubmit={handleSubscribe} className={styles.form}>
            <Input
              name="email"
              placeholder="E-mail address"
              className={styles.subscribeInput}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button type="submit" className={styles.subscribeButton}>
              Subscribe
            </Button>
          </form>

          <p className={styles.privacyNote}>
            Emails subject to{' '}
            <Link href="#" className={styles.privacyLink}>
              privacy policy
            </Link>
          </p>

          <div className={styles.buttonGroup}>
            {showLoginModal ? (
              <Button onClick={handleOpenLoginModal}>Join as Talent</Button>
            ) : null}
            {showOnboardingModal ? (
              <Button onClick={handleOpenOnboardingModal}>
                Join as Talent
              </Button>
            ) : null}
            {redirectToDashboard ? (
              <LinkButton link={DASHBOARD} size="large" variant="secondary">
                Join as Talent
              </LinkButton>
            ) : null}

            <LinkButton
              link={`${getCBEnvLink()}/sign-in`}
              size="large"
              variant="secondary"
            >
              Launch A Campaign
            </LinkButton>
          </div>
        </div>

        {/* Footer Links Grid */}
        <div className={styles.footerLinks}>
          <div className={styles.catActionLinks}>
            <div>
              <Text className={styles.footerLinkTitle}>Links</Text>
              <ul className={styles.footerLinkList}>
                {EXTERNAL_COMPANY_LINKS.map((item) => (
                  <li key={item.label}>
                    <Link
                      href={item.link}
                      prefetch={false}
                      className={styles.footerLink}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Text className={styles.footerLinkTitle}>Product</Text>
              <ul className={styles.footerLinkList}>
                {CONTACT_LINKS.map((item) => (
                  <li key={item.label}>
                    <Link
                      href={item.link}
                      prefetch={false}
                      className={styles.footerLink}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <Text className={styles.footerLinkTitle}>Join MILLIONS</Text>
              <ul className={styles.footerLinkList}>
                {EXPLORE_LINKS.map((link) => (
                  <li key={link.label}>
                    <a
                      key={link.label}
                      href={link?.link}
                      onClick={
                        link.onClick
                          ? (e) => {
                              e.preventDefault();
                              onFooterCtaClicked(link);
                            }
                          : undefined
                      }
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles.socialAndStores}>
            <div className={styles.downloadAppWrapper}>
              <DownloadApp />
            </div>
            <SocialNetworks className={styles.socialLinks} />
          </div>
        </div>
      </div>

      <div className={styles.bottomWrapper}>
        <div className={styles.watermark}>
          <FooterLogo />
        </div>
        <div className={styles.bottomSection}>
          <div>
            <Text color="lights-low"> © 2025 MILLIONS.co </Text>
            <LinkButton
              link="mailto:info@millions.co"
              subVariant="text"
              variant="secondary"
              size="small"
              className={styles.bottomBarLink}
              aria-label="Help"
            >
              <Text color="lights-low"> Help </Text>
            </LinkButton>
            <LinkButton
              link={PRIVACY_POLICY}
              subVariant="text"
              variant="secondary"
              size="small"
              className={styles.bottomBarLink}
            >
              <Text color="lights-low"> Privacy </Text>
            </LinkButton>
            <LinkButton
              link={TERMS_OF_SERVICE}
              subVariant="text"
              variant="secondary"
              size="small"
              className={styles.bottomBarLink}
            >
              <Text color="lights-low"> Terms and Conditions </Text>
            </LinkButton>
          </div>
          <div className={styles.pageInfo}>
            <MillionsIcon name="infoImage" size={12} />
            <Text color="light-low"> EN | United States | $USD </Text>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
