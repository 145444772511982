import { useState } from 'react';
import cn from 'classnames';
import HtmlEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
// Helpers
import { parseForInnerHTML } from 'helpers/textEditor';
// Types
import { HTMLEllipsisProps } from 'react-lines-ellipsis';
// Hooks
import { useHasMounted, useBreakpoints } from 'hooks';

import styles from './EllipsisText.module.scss';

type BasicReactLinesEllipsisProps = Omit<
  HTMLEllipsisProps,
  'maxLine' | 'unsafeHTML'
>;

type EllipsisBreakpoints = 'xs' | 's' | 'm' | 'ml' | 'l' | 'xl' | 'xxl';

type MaxLine = number | RequireAtLeastOne<Record<EllipsisBreakpoints, number>>;

type EllipsisTextProps = BasicReactLinesEllipsisProps & {
  maxLine?: MaxLine;
  text: string;
  isGold?: boolean;
  customMoreText?: string;
  onReadMoreClick?: () => void;
  setShowMore?: (boolean) => void;
};

const ResponsiveHtmlEllipsis = responsiveHOC()(HtmlEllipsis);

const EllipsisText = ({
  className,
  maxLine = 3,
  text,
  isGold = false,
  onReadMoreClick,
  setShowMore,
  customMoreText,
  ...rest
}: EllipsisTextProps) => {
  const [ellipsis, setEllipsis] = useState<boolean>(true);

  const hasMounted = useHasMounted();

  const {
    isBreakpointXXL,
    isBreakpointXL,
    isBreakpointL,
    isBreakpointML,
    isBreakpointM,
    isBreakpointS,
    isBreakpointXS,
  } = useBreakpoints();

  const computeBreakpointMaxLine = () => {
    if (typeof maxLine === 'number') {
      return maxLine;
    }

    const { xxl, xl, l, ml, m, s, xs } = maxLine;

    if (xxl && isBreakpointXXL) {
      return xxl;
    } else if (xl && isBreakpointXL) {
      return xl;
    } else if (l && isBreakpointL) {
      return l;
    } else if (ml && isBreakpointML) {
      return ml;
    } else if (m && isBreakpointM) {
      return m;
    } else if (s && isBreakpointS) {
      return s;
    } else if (xs && isBreakpointXS) {
      return xs;
    }
  };

  const computedMaxLine = computeBreakpointMaxLine();
  const isHTMLText = /(<([^>]+)>)/gi.test(text);

  const ellipsisHTMLText = isGold
    ? `... <em style="text-transform:capitalize; text-decoration: none; color: #d2ab64;">${
        customMoreText ?? 'show more'
      }</em>`
    : `... <em>${customMoreText ?? 'read more'}</em>`;

  const handleTextClick = () => {
    if (onReadMoreClick) {
      onReadMoreClick();
    } else {
      setEllipsis(!ellipsis);
      setShowMore && setShowMore(ellipsis);
    }
  };

  if (
    typeof window === 'undefined' ||
    !hasMounted ||
    !ellipsis ||
    !computedMaxLine
  ) {
    if (isHTMLText) {
      return (
        <>
          <div
            className={className}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTML(text),
            }}
          />
          {isGold && (
            <button onClick={handleTextClick} className={styles.showLessButton}>
              Show Less
            </button>
          )}
        </>
      );
    }

    return <p className={className}>{text}</p>;
  }

  return (
    <div
      className={cn(styles.root, className)}
      role="button"
      onClick={handleTextClick}
      onKeyPress={handleTextClick}
      tabIndex={0}
      aria-label="Read more"
    >
      <ResponsiveHtmlEllipsis
        ellipsisHTML={ellipsisHTMLText}
        basedOn="letters"
        component="div"
        unsafeHTML={parseForInnerHTML(text)}
        maxLine={computedMaxLine}
        {...rest}
      />
    </div>
  );
};

export default EllipsisText;
