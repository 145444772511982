import React from 'react';
import cn from 'classnames';
// Types
import { GetStoreBySlug_store_socialMedia } from 'api/store/types/GetStoreBySlug';
// UI3
import LinkButton, { ButtonIcon } from 'ui3/LinkButton/LinkButton';
// Helpers
import { defaultLinks, mapSocialLinks } from './SocialNetworkHelper';
// Styles
import styles from './SocialNetworks.module.scss';

export type SocialNetworksProps = {
  links?: GetStoreBySlug_store_socialMedia[];
  className?: string;
  showExtra?: boolean;
};

const SocialNetworks: React.FC<SocialNetworksProps> = ({
  links,
  className,
  showExtra,
}) => {
  if (links?.length === 0 && showExtra) {
    return null;
  }
  const socialLinks = links?.length ? mapSocialLinks(links) : defaultLinks;

  return (
    <div className={cn(styles.socialLinks)}>
      {showExtra && <p className={styles.label}>LINKS</p>}
      <div className={cn(!className && styles.socialLinksGrid, className)}>
        {socialLinks.map((social) => (
          <div key={social.platform} className={styles.socialLinkWrapper}>
            <div className={styles.socialLinkHeader}>
              <LinkButton
                link={social.url}
                variant="secondary"
                size="medium"
                icon={social.icon as ButtonIcon}
                className={styles.socialLink}
                aria-label={social.platform}
              />

              {showExtra && (
                <div className={styles.extraMetaData}>
                  <span className={styles.platformName}>{social.platform}</span>
                  <a href={social.url} className={styles.profileUrl}>
                    {social.url}
                  </a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialNetworks;
