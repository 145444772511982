import Image from 'ui3/Image/Image';
// Styles
import styles from './DownloadApp.module.scss';

export const DownloadApp = () => {
  return (
    <div className={styles.root}>
      <a
        href="https://apps.apple.com/rs/app/millions-athletes-creators/id6478323505"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src="/images/logos/download-app-apple.svg"
          alt="Apple App Store"
          /**
           * Bypass cdn and no need for image optimization for svg
           * https://nextjs.org/docs/basic-features/image-optimization#unoptimized
           */
          unoptimized
        />
      </a>

      <a
        href="https://play.google.com/store/apps/details?id=com.millions.store.prod&hl=en"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          src="/images/logos/download-app-google.svg"
          alt="Google Play Store"
          /**
           * Bypass cdn and no need for image optimization for svg
           * https://nextjs.org/docs/basic-features/image-optimization#unoptimized
           */
          unoptimized
        />
      </a>
    </div>
  );
};
