import { SocialMediaType } from 'api/graphql-global-types';

export interface SocialLink {
  platform: string;
  icon: string;
  url: string;
}

export const defaultLinks: SocialLink[] = [
  {
    platform: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/millionsdotco',
  },
  {
    platform: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/millionsdotco/',
  },
  { platform: 'X', icon: 'x', url: 'https://x.com/millionsdotco' },
  {
    platform: 'TikTok',
    icon: 'tiktok',
    url: 'https://www.tiktok.com/@millionsdotco',
  },
  {
    platform: 'You Tube',
    icon: 'youtube',
    url: 'https://www.youtube.com/channel/UCyIadicC0Ac0R4yU3-cqRgA',
  },
];

/**
 * Maps social media data from GraphQL to SocialLink objects
 * @param socialMediaItems - Array of social media items from API
 * @returns Array of SocialLink objects with appropriate platform and icon values
 */
export const mapSocialLinks = (
  socialMediaItems: { url: string | null; type: SocialMediaType }[]
): SocialLink[] => {
  return socialMediaItems.map((item) => {
    const { url, type } = item;

    // Map platform names and icons
    let platform: string;
    let icon: string;

    switch (type) {
      case SocialMediaType.Twitter:
        platform = 'X';
        icon = 'x';
        break;
      case SocialMediaType.Youtube:
        platform = 'You Tube';
        icon = 'youtube';
        break;
      case SocialMediaType.Tiktok:
        platform = 'TikTok';
        icon = 'tiktok';
        break;
      case SocialMediaType.Facebook:
        platform = 'Facebook';
        icon = 'facebook';
        break;
      case SocialMediaType.Instagram:
        platform = 'Instagram';
        icon = 'instagram';
        break;
      default:
        platform = type;
        icon = type;
    }

    return {
      platform,
      icon,
      url: url || '#', // Handle null URLs with a fallback
    };
  });
};
